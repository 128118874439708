<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<portauthorityconfig-modals :modelName="modelName"></portauthorityconfig-modals>
	</div>
</template>

<script>
export default {
	name: 'portauthorityconfig-grid',
	components: {},
	data() {
		return {
			modelName: 'portauthorityconfig',
			actions: [],
			modelColumnDefs: {}
		};
	}
};
</script>
